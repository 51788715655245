<template>
    <q-layout  view="hHh lpR fff"> <!-- :theme="store.theme" -->
        <HeaderMain />
        <q-page-container fluid>
            <loader v-if="store.loader" />
            <q-page>
                <slot />
            </q-page>
        </q-page-container>
        <FooterMain />
    </q-layout>
</template>
<script setup>
import { useStore } from '@/store/store';
const store = useStore(); 

useHead({
    htmlAttrs: {
        class: 'snap',
    },
    bodyAttrs: {
        class: 'overscroll',
    },
});

</script>
<style lang="scss">
@import '~/public/assets/variables';

@media (min-width: $breakpoint-medium) {
    .snap {
        scroll-snap-type: y mandatory;
        scroll-behavior: smooth;
        scroll-padding: 120px;          // changed from 122px 0 0 0 in order to prevent the jump down on load
    }

    .overscroll {
        /* There is a bug in Chrome: https://stackoverflow.com/questions/63412354/scroll-snap-bug-chrome-on-mac-allowing-scroll-up-into-the-html-void */
        overscroll-behavior-y: none;
    }
}

// this layout is used in {video_slug}.vue -- light mode on.

</style>
